<template>
  <div>
    <div v-if="faDaDaSrc">
      <div id="fadadaIframe"  style="height:100%;margin:-20px">
      <iframe :src="faDaDaSrc" style="height:100%; width:100%;margin:0;border:0;"> </iframe>
      </div>
    </div>
    <div v-else>
      <el-empty style="margin-top:50px" description="企业未授权，请先去企业授权页面进行企业授权"></el-empty>
      
    </div>
  </div>
</template>

<script>
export default {
  data() {

    return {
      faDaDaSrc: null
    }
  },
  created() {
    this.getInfo() // 获取印章管理
  },
  computed: {
  },
  mounted() {
    this.setMyIframeHeight();
     window.addEventListener("resize", () => this.setMyIframeHeight(), false);
  },
  beforeDestroy() {
    window.removeEventListener("resize", this.setMyIframeHeight(), false);
  },
  methods: {
    getInfo() {
      let params = {
        process_id: '00000096', // 流程ID ---95是查询印章管理
        nodeInfo: {} // 传参  查询则是查询条件  可传可不传等
      }
      this.$post('a6c611cc-ded1-4a49-8823-0c66f6ebf7d9', params)
        .then(result => {
          const { succeed, data, errorMsg } = result
          if (succeed === 0) {
            if (data?.resourceUrl) {
              this.faDaDaSrc = data.resourceUrl;
              this.setMyIframeHeight();
            }
          } else {
            this.$message.error(errorMsg)
          }

        })
        .catch(err => { })
    },

    setMyIframeHeight(){
        // 页面可视高-顶部实际高-底部实际高-多余部分高度
      let that = this;
      this.$nextTick(() => {
        let headerHeight = 60;
        let footerHeight =0;
        let bodyHeight = document.body.clientHeight;
        let outHeight =parseFloat(bodyHeight - headerHeight - footerHeight)-5+ "px";
        document.querySelector("#fadadaIframe").style.height = outHeight;
      });
    },
    formatDateTime(newTime) {
        if (!newTime) return '';
        var date = new Date(newTime); //将时间戳转换成日期对象
        console.log(newTime)
        var year = date.getFullYear();  //获取年
        var month = ('0' + (date.getMonth() + 1)).slice(-2); //获取月
        var day = ('0' + date.getDate()).slice(-2);//获取日
        var hours = ('0' + date.getHours()).slice(-2);//获取时
        var minutes = ('0' + date.getMinutes()).slice(-2);//获取分
        var seconds = ('0' + date.getSeconds()).slice(-2);//获取秒
        return year + '-' + month + '-' + day + ' ' + hours + ':' + minutes + ':' + seconds;
      }
  }
}
</script>

<style lang="scss" scoped>

.title {
  border-bottom: 3px solid #1d74e0;
  height: 35px;
  line-height: 27px;
  margin-top: 15px;
  font-size: 16px;
  font-weight: bold;
  color: #666666;
  padding-left: 30px;
}

.form-info {
  margin: 20px 0;
  padding: 10px;
  background: #fff;
  border: 1px solid #e5e5e5;

}

.authoriize ::v-deep .el-button--text {

  color: #DB0E0E;
  padding: 0;
}

.authoriize ::v-deep .have {

  color: #00ff00;
  padding: 0;
}

.form-info ::v-deep .label-cls {
  text-align: right;
  width: 120px;
  display: inline-block;
  margin-right: 30px;
}
#fadadaIframe{
  padding: -20px;
}
</style>
